import {UPDATE_ANKETA_ERROR, UPDATE_ANKETA_PENDING, UPDATE_ANKETA_SUCCESS} from "../../../types/AdminTypes";
import SettingsService from "../../../services/SettingsService";
import {getSelectedAnketaContentAction} from "../settings/getSelectedAnketaContentAction";


export const saveForm = (body) => async dispatch => {
    dispatch({
        type: UPDATE_ANKETA_PENDING
    });
    await new SettingsService().saveForm(body).then(res=>{
        dispatch({
            type: UPDATE_ANKETA_SUCCESS,
            payload: res.data
        })
        dispatch(getSelectedAnketaContentAction(body.id))
    }).catch(err=>{
        dispatch({
            type: UPDATE_ANKETA_ERROR
        })
    })
};