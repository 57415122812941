import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import styles from '../../assets/styles/FaqStyles/FaqList.module.scss'
import Plus from '../../assets/icons/plus.svg'
import redPlus from '../../assets/icons/plusRed.svg'
import Minus from '../../assets/icons/minus.svg'


const FaqList = ({location,faqDatas}) => {
    console.log(faqDatas);
    const {userData} = useSelector(state => state.AuthPage);
    const [hover,setHover] = useState('');
    const [open,setOpen] = useState('');
    const [faqData,setFaqData] = useState([]);


    const handleOpen = (id) => {
        if (open!==id){
            setOpen(id)
        }else{
            setOpen('')
        }
    };

    useEffect(()=>{
        // if (userData){
        //     if (userData.payment){
        //         if (userData.payment.payment_status_id == '3'){
        //             setFaqData([
        //                 {id:1,
        //                     title:'Зачем нужно заполнять анкеты?',
        //                     text:"Ваши ответы на вопросы юридической, финансовой и стратегической анкеты помогают нам полноценно изучить ваш бизнес и подготовить весь перечень необходимых документов "},
        //                 {id:2,
        //                     title:'Что следует указать в ответе на вопрос «Какие программные продукты вы применяете для обучения персонала?»',
        //                     text:"Вы можете добавить ссылку на Google Drive или любой другой удобный для вас ресурс, куда будут загружаться обучающие материалы. "},
        //                 {id:3,
        //                     title:'Что делать если какие-то траты, которые нужно внести в финансовую анкету, отсутствуют? (Например, нет аренды помещения)',
        //                     text:"В данном случае вы можете просто пропустить этот вопрос или оставить значение «0». "},
        //                 {id:4,
        //                     title:'Какую сумму нужно поставить минимальной для штрафа?',
        //                     text:"Регламентируя ответственность за франчайзинговые правонарушения, мы рекомендуем минимальную сумму штрафа 200 000 тенге. "},
        //                 {id:5,
        //                     title:'Какие штрафы бывают?',
        //                     text:"В основном штрафы предусмотрены для сотрудников компании – штраф за опоздание, использование нецензурной лексики, несоответствие дресс-коду, жалобы со стороны клиентов и т.д. "},
        //                 {id:6,
        //                     title:'Что такое выручка?',
        //                     text:"Выручка – совокупность полученных средств за предоставление услуг или продажу товаров являются основным доходом компании. "},
        //                 {id:7,
        //                     title:'Что делать если ИИК и расчетный счет одинаковые?',
        //                     text:"ИИК и расчетный счет должны быть одинаковыми. Заполните обе графы или оставьте одну из них пустой."}
        //             ])
        //         }else{
        //             setFaqData([
        //                 {id:1,title:'Какой бизнес подходит для франшизы?',text:'Развитие франшизы подходит для малого и среднего бизнеса, который стабильно приносит прибыль. Франчайзинговая бизнес-модель подойдет для Вашего дела, если Вы, как владелец, готовы и открыты к продуктивному сотрудничеству. '},
        //                 {id:2,title:'В чем отличие франшизы от филиала?',text:"Филиал – это часть основной компании, расположенной на удаленной от филиала территории, зачастую является подразделением или отделом головной компании. \n" +
        //                         "Франшиза (франчайзинг) – это форма делового сотрудничества нескольких компаний, при котором компания-владелец бренда (франчайзер) перепродает право его использования и технологию независимому предприятию (франчайзи). "},
        //                 {id:3,title:'Какую выгоду мне даст франшиза?',
        //                     text:'<div>' +
        //                         '<p>- Масштабирование бизнеса и его расширение в регионах и на международном уровне при минимальных затратах;</p>' +
        //                         '<p>- Минимизация рисков. 85% вновь образованных предприятий прекращают свою деятельность в течение первых пяти лет, в рамках франчайзинга этот показатель снижается до 14%.</p>'+
        //                         '<p>- Популяризация бренда и его узнаваемость. Франчайзинговая бизнес-модель предполагает расширение компании, увеличение количества франшизных точек обеспечивает рост узнаваемости бренда всей компании.</p>'+
        //                         '<p>- Постоянный доход. Франчайзинг позволяет получить франчайзеру дополнительный доход для развития бизнеса, он складывается из двух составляющих: первоначальный взнос (паушальный платеж) и регулярные ежемесячные платежи (роялти).  </p>'+
        //                         '</div>'
        //                 },
        //                 {id:4,title:'Как продавать франшизу?',text:'Мы рекомендуем нанять менеджера для продвижения и продажи франшизы. Команда Scaleup также предлагает своим клиента разместить актуальную информацию на торговой площадке <a href="https://marketplace.scaleup.plus" target="_blank">https://marketplace.scaleup.plus</a>, которая помогает владельцам франшизы и потенциальным покупателям находить друг друга для взаимовыгодного партнерства.'},
        //                 {id:5,title:'Как рассчитать стоимость франшизы?',text:"Стоимость франшизы зависит от объема прав и поддержки, которую вы готовы предоставить своему будущему партнеру. Среднюю стоимость франшизы Вы можете рассчитать самостоятельно, воспользовавшись калькулятором франшиз на нашем сайте www.scaleup.plus "},
        //             ])
        //         }
        //     }
        // }
    },[]);

    // useEffect(()=>{
    //     setOpen('')
    // },[faqData]);


    return(
        <div className={styles.faqList}>
            {faqDatas?.map((el,index)=>(
                <div key={index} onClick={()=>handleOpen(el.id)} onMouseEnter={()=>setHover(el.id)} onMouseLeave={()=>setHover('')}  className={styles.rowContainer}>
                <div className={styles.faqRow}>
                            {open !==el.id ? (
                                <>
                                    <div key={index} className={styles.faqTitle}>{el.title}</div>
                                    {hover === el.id ? (
                                        <div>
                                            <img src={redPlus} alt="plus"/>
                                        </div>
                                    ):(
                                        <div>
                                            <img src={Plus} alt="plus"/>
                                        </div>
                                    )}
                                </>
                            ):(
                                <>
                                    <div style={{color: "red"}} key={index} className={styles.faqTitle}>{el.title}</div>
                                    <div style={{transition:'.4s'}}>
                                        <img src={Minus} alt="minus"/>
                                    </div>
                                </>
                            )}
                </div>
                    {open === el.id ? (
                        <div dangerouslySetInnerHTML={{__html: el.text}} className={styles.faqText}></div>
                    ):(
                        <div></div>
                    )}
                </div>
            ))}
        </div>
    )
};
export default FaqList