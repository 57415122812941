import React, {useState} from "react";
import styles from "../../../assets/styles/AdminStyles/ContentInfoEditModal.module.scss"
import InputAdornment from "@material-ui/core/InputAdornment";
import Comment from "../../../assets/icons/comment.svg";
import TextField from "@material-ui/core/TextField/TextField";
import Select from '@material-ui/core/Select';
import {useDispatch} from "react-redux";
import "../../../assets/styles/AdminStyles/Tag/SaveFormModal.css"
import MenuItem from '@material-ui/core/MenuItem';
import {CHANGE_DESCRIPTION, CHANGE_LINK, CHANGE_NAME} from "../../../types/AdminTypes";
import {saveForm} from "../../../redux/actions/form/saveForm";
import { WithContext as ReactTags } from 'react-tag-input';
import {getSelectedAnketaContentAction} from "../../../redux/actions/settings/getSelectedAnketaContentAction";
import {useParams} from "react-router-dom";


const KeyCodes = {
    comma: 188,
    enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

const SaveFormModal = ({data,closeInfoEditor, title, questionID}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [tags, setTags] = useState([]);
    const [formType, setFormType] = useState(1);
    const [tagsEnable,setTagsEnable] = useState(true);
    const [formTitle, setFormTitle] = useState();
    const [formPlaceholder, setFormPlaceholder] = useState();
    const [formKey, setFormKey] = useState()
    const [inputType, setInputType] = useState(1)

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };
    const handleChange = (event) => {
        console.log(event.target.value);
        setInputType(event.target.value)
        if(event.target.value == 2 || event.target.value == 4){
            setTagsEnable(false)
        }
        else{
            setTagsEnable(true)
        }
        setFormType(event.target.value);
    };
    const handleAddition = tag => {
        setTags([...tags, tag]);
    };
    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };
    const changeName = event => {
        dispatch({
            type: CHANGE_NAME,
            payload: event.target.value
        });
    };
    const changeTitle = event => {
        setFormTitle(event.target.value);
    }
    const changePlaceholder = event => {
        setFormPlaceholder(event.target.value);
    }
    const changeKey = event => {
        setFormKey(event.target.value);
    }

    const changeDescription = event => {
        dispatch({
            type: CHANGE_DESCRIPTION,
            payload: event.target.value
        });
    };

    const changeLink = event => {
        dispatch({
            type: CHANGE_LINK,
            payload: event.target.value
        });
    };

    const submit = () => {
        const body = {
            type: inputType,
            id: data.id,
            title: formTitle,
            placeholder: formPlaceholder,
            key: formKey,
            options: tags,
            question_id: questionID
        };
        dispatch(saveForm(body));
        closeInfoEditor()
    };

    return(
        <div className={styles.modal}>
            <p>{title}</p>
            <TextField
                autoComplete={"off"}
                className={data.name ? `${styles.textField} on` : `${styles.textField} off`}
                id="outlined-basic"
                name="title"
                label={"Заголовок"}
                type="text"
                variant="outlined"
                onChange={changeTitle}
                value={formTitle}
            />
            <TextField
                autoComplete={"off"}
                style={{marginTop:'15px'}}
                className={data.link ? `${styles.textField} on` : `${styles.textField} off`}
                id="outlined-basic"
                name="placeholder"
                type="text"
                value={formPlaceholder}
                variant="outlined"
                label={"Placeholder"}
                onChange={changePlaceholder}
            />
            <TextField style={{marginTop:'15px', width: "100%"}}
                       id="select outlined-basic"
                       name={"type"}
                       label="Тип ввода"
                       value={formType}
                       select
                       onChange={handleChange}>
                <MenuItem value={1}>input</MenuItem>
                <MenuItem value={2}>radio</MenuItem>
                <MenuItem value={3}>file</MenuItem>
                <MenuItem value={4}>checkbox</MenuItem>
            </TextField>
            <TextField
                autoComplete={"off"}
                style={{marginTop:'15px'}}
                className={data.link ? `${styles.textField} on` : `${styles.textField} off`}
                id="outlined-basic"
                onChange={changeKey}
                value={formKey}
                name="key"
                type="text"
                variant="outlined"
                label={"Ключ"}
            />
            <div>
                <ReactTags
                    id="outlined-basic"
                    style={{marginTop:'15px', width: "100%"}}
                    tags={tags}
                    delimiters={delimiters}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    name="options"
                    placeholder={"Введите варианты через запятую"}
                    inputFieldPosition="top"
                    autocomplete
                    editable
                    readOnly={tagsEnable}
                />
            </div>
            <div className={styles.action}>
                <button onClick={submit} className={styles.btn}>
                Сохранить
            </button>
                <button onClick={closeInfoEditor} style={{backgroundColor:"#ccc",color:"#171717"}} className={styles.btn}>
                    Отмена
                </button>
            </div>
        </div>
    )
};

export default SaveFormModal;