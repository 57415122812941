export const CREATE_ANKETA_FORM  = 'CREATE_ANKETA_FORM';
export const SET_INPUT_ANKETA_VALUE = 'SET_INPUT_ANKETA_VALUE';
export const SET_CHECKBOX_ANKETA_VALUE= 'SET_CHECKBOX_ANKETA_VALUE';
export const SET_RADIO_ANKETA_VALUE = 'SET_RADIO_ANKETA_VALUE';

export const SAVE_ANKETA_VALUE_PENDING = 'SAVE_ANKETA_VALUE_PENDING';
export const SAVE_ANKETA_VALUE_SUCCESS = 'SAVE_ANKETA_VALUE_SUCCESS';
export const SAVE_ANKETA_VALUE_ERROR = 'SAVE_ANKETA_VALUE_ERROR';

export const SAVE_FILE_IN_ANKETA = 'SAVE_FILE_IN_ANKETA';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const CHECK_COMPLETED_ANSWERS = 'CHECK_COMPLETED_ANSWERS';
