import React, {useState} from "react";
import styles from "../../../assets/styles/AdminStyles/ContentInfoEditModal.module.scss"
import InputAdornment from "@material-ui/core/InputAdornment";
import Comment from "../../../assets/icons/comment.svg";
import TextField from "@material-ui/core/TextField/TextField";
import {useDispatch} from "react-redux";
import {CHANGE_DESCRIPTION, CHANGE_LINK, CHANGE_NAME} from "../../../types/AdminTypes";
import {saveQuestion} from "../../../redux/actions/question/saveQuestion";
import {getSelectedAnketaContentAction} from "../../../redux/actions/settings/getSelectedAnketaContentAction";
import {useParams} from "react-router-dom";

const AddQuestionModal = ({data,closeInfoEditor}) => {
    const {id} = useParams();
    const dispatch = useDispatch();

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [example, setExample] = useState()
    const changeName = event => {
        setTitle(event.target.value)
    };

    const changeDescription = event => {
        setDescription(event.target.value)
    };

    const changeLink = event => {
        setExample(event.target.value)
    };

    const submit = () => {
        const body = {
            type: "section",
            id: data.id,
            title: title,
            description: description,
            example: example
        };
        dispatch(saveQuestion(body));
        closeInfoEditor()
    };

    return(
        <div className={styles.modal}>
            <TextField
                autoComplete={"off"}
                className={data.name ? `${styles.textField} on` : `${styles.textField} off`}
                id="outlined-basic"
                name="title"
                label={"Заголовок"}
                type="text"
                variant="outlined"
                onChange={changeName}
            />
            <div className={styles.textarea}>
                            <textarea
                                className={styles.textField}
                                name="description"
                                onChange={changeDescription}
                                // placeholder={placeholder}
                                id=""
                                cols="4"
                                rows="4"
                            >
                            </textarea>
            </div>
            <TextField
                autoComplete={"off"}
                style={{marginTop:'15px'}}
                className={data.link ? `${styles.textField} on` : `${styles.textField} off`}
                id="outlined-basic"
                onChange={changeLink}
                name="example"
                type="text"
                variant="outlined"
                label={"Примеры"}
            />
            <div className={styles.action}>
                <button onClick={submit} className={styles.btn}>
                Сохранить
            </button>
                <button onClick={closeInfoEditor} style={{backgroundColor:"#ccc",color:"#171717"}} className={styles.btn}>
                    Отмена
                </button>
            </div>
        </div>
    )
};

export default AddQuestionModal;