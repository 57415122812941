export const PAYMENT_TABLE_PENDING = "PAYMENT_TABLE_PENDING";
export const PAYMENT_TABLE_SUCCESS = "PAYMENT_TABLE_SUCCESS";
export const PAYMENT_TABLE_ERROR = "PAYMENT_TABLE_ERROR";

export const QUESTIONNAIRE_TABLE_PENDING = "QUESTIONNAIRE_TABLE_PENDING";
export const QUESTIONNAIRE_TABLE_SUCCESS = "QUESTIONNAIRE_TABLE_SUCCESS";
export const QUESTIONNAIRE_TABLE_ERROR = "QUESTIONNAIRE_TABLE_ERROR";


export const QUESTIONNAIRE_CURRENT_ITEM_PENDING = "QUESTIONNAIRE_CURRENT_ITEM_PENDING";
export const QUESTIONNAIRE_CURRENT_ITEM_SUCCESS = "QUESTIONNAIRE_CURRENT_ITEM_SUCCESS";
export const QUESTIONNAIRE_CURRENT_ITEM_ERROR = "QUESTIONNAIRE_CURRENT_ITEM_SUCCESS";

export const WORKERS_DATA_PENDING = "WORKERS_DATA_PENDING";
export const WORKERS_DATA_SUCCESS = "WORKERS_DATA_SUCCESS";
export const WORKERS_DATA_ERROR = "WORKERS_DATA_ERROR";

export const SERVICES_DATA_PENDING = "SERVICES_DATA_PENDING";
export const SERVICES_DATA_SUCCESS = "SERVICES_DATA_SUCCESS";
export const SERVICES_DATA_ERROR = "SERVICES_DATA_ERROR";

export const USERS_LIST_PENDING = "USERS_LIST_PENDING";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_ERROR = "USERS_LIST_ERROR";

export const SECTIONS_PENDING = "SECTIONS_PENDING";
export const SECTIONS_SUCCESS = "SECTIONS_SUCCESS";
export const SECTIONS_ERROR = "SECTIONS_ERROR";

export const PRODUCTION_TABLE_PENDING = "PRODUCTION_TABLE_PENDING";
export const PRODUCTION_TABLE_SUCCESS = "PRODUCTION_TABLE_SUCCESS";
export const PRODUCTION_TABLE_ERROR = "PRODUCTION_TABLE_ERROR";

export const PRODUCTION_CURRENT_PENDING = "PRODUCTION_CURRENT_PENDING";
export const PRODUCTION_CURRENT_SUCCESS = "PRODUCTION_CURRENT_SUCCESS";
export const PRODUCTION_CURRENT_ERROR = "PRODUCTION_CURRENT_ERROR";

export const GET_CONTENT_ANKETA_PENDING = "GET_CONTENT_ANKETA_PENDING";
export const GET_CONTENT_ANKETA_SUCCESS = "GET_CONTENT_ANKETA_SUCCESS";
export const GET_CONTENT_ANKETA_ERROR = "GET_CONTENT_ANKETA_ERROR";


export const GET_SELECTED_ANKETA_CONTENT_PENDING = "GET_SELECTED_ANKETA_CONTENT_PENDING";
export const GET_SELECTED_ANKETA_CONTENT_SUCCESS = "GET_SELECTED_ANKETA_CONTENT_SUCCESS";
export const GET_SELECTED_ANKETA_CONTENT_ERROR = "GET_SELECTED_ANKETA_CONTENT_ERROR";

export const CHANGE_NAME = "CHANGE_NAME";
export const CHANGE_DESCRIPTION = "CHANGE_DESCRIPTION";
export const CHANGE_LINK = "CHANGE_LINK";

export const UPDATE_ANKETA_PENDING = "";
export const UPDATE_ANKETA_SUCCESS = "";
export const UPDATE_ANKETA_ERROR = "";

export const UPDATE_FORM_PENDING = 'UPDATE_FORM_PENDING';
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS';
export const UPDATE_FORM_ERROR = 'UPDATE_FORM_ERROR';




