import React, {useState} from "react";
import styles from "../../assets/styles/AdminStyles/AdminSettingsTableContent.module.scss";
import editIcon from "../../assets/icons/editTableIcon.svg";
import trashIcon from "../../assets/icons/trashIcon.svg";
import docsIcon from "../../assets/icons/docs.svg";
import Modal from "react-modal";
import DeleteQuestionModal from "../../components/AdminComponents/modals/DeleteQuestionModal";
import SaveFormModal from "../../components/AdminComponents/modals/SaveFormModal";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        borderRadius          : '32px',
        padding               : 0
    }
};

const ContentTableQuestions = ({data=[],setForm,open,setType}) => {

    const [deleteFormModalIsOpen, setDeleteFormModalIsOpen] = useState(false);
    const [selectedFormId, setSelectedFormId] = useState(null);
    const [addFormModalIsOpen, setAddFormModalIsOpen] = useState(false);
    const [questionID, setQuestionID] = useState()
    const [deleteType, setDeleteType] = useState("form");
    const updateQuestion = (question,type) => {
        setType(type);
        setForm(question);
        open();
    };
    const deleteForm = (form, type = "form") => {
        setSelectedFormId(form.id);
        setDeleteType(type);
        setDeleteFormModalIsOpen(true);
    }
    const addFormModal = (el) => {
        setQuestionID(el.id)
        setAddFormModalIsOpen(true)
    }
    const closeFormModal = () => {
        setAddFormModalIsOpen(false)
    }
    const closeDeleteFormModal = () => {
        setDeleteFormModalIsOpen(false);
    };
    return(
        <>
            <Modal
                isOpen={deleteFormModalIsOpen}
                style={customStyles}
            >
                <DeleteQuestionModal
                    title={"Удаление вопроса"}
                    id={selectedFormId}
                    text={`Вы точно хотите удалить вопрос`}
                    closeDeleteModal={closeDeleteFormModal}
                    anketa={data}
                    type={deleteType}>
                </DeleteQuestionModal>
            </Modal>
            <Modal
            isOpen={addFormModalIsOpen}
            style={customStyles}
            >
                <SaveFormModal
                    title={"Добавление раздела"}
                    closeInfoEditor={closeFormModal}
                    data={data}
                    questionID={questionID}
                ></SaveFormModal>
            </Modal>
            {data && data.questions && data.questions.map((el,index)=>(
                <>
                    <tr>
                        <td style={{border:'1px solid grey',backgroundColor:'#ccc'}} colSpan={2}>{el.description}</td>
                        <td style={{border:'1px solid grey',backgroundColor:'#ccc'}} colSpan={2}>{el.example}</td>
                        <td style={{cursor:'pointer'}}>
                            <div className={styles.action}>
                                <img onClick={()=>{addFormModal(el)}} src={docsIcon} alt="add"/>
                                <img onClick={()=>updateQuestion(el,'title')} src={editIcon} alt="edit"/>
                                <img onClick={()=>{
                                    deleteForm(el, "question")
                                }} src={trashIcon} alt="delete"/>
                            </div>
                        </td>
                    </tr>
                <>
                    {el.forms.map((e,index2)=>(
                        <tr>
                            <td>
                                {e.id}
                            </td>
                            <td>
                                {"${"+e.key+"}"}
                            </td>
                            <td>
                                {e.title}
                            </td>
                            <td>
                                {e.type == '1' && (
                                    <>
                                        input
                                    </>
                                )}
                                {e.type == '2' && (
                                    <>
                                        radio
                                    </>
                                )}
                                {e.type == '3' && (
                                    <>
                                        file
                                    </>
                                )}
                                {e.type == '4' && (
                                    <>
                                        checkbox
                                    </>
                                )}
                            </td>
                            <td style={{cursor:'pointer'}}>
                                <div className={styles.action}>
                                    <img onClick={()=>updateQuestion(e,'question')} key={e.id} src={editIcon} alt="edit"/>
                                    <img onClick={()=>{
                                        deleteForm(e, "form")
                                    }} src={trashIcon} alt="edit"/>
                                </div>
                            </td>
                        </tr>
                    ))}
                </>
                    </>
            ))}
        </>
    )
};

export default ContentTableQuestions